import CollectionCardRhinox from "./CollectionCardRhinox";
import rhinoxImage from "../../../assets/img/rhinox.jpg";
import { useCollectionsQuery } from "../../../hooks/useNftCollections";
import { Spinner } from "../../../components/Spinner";
import styles from "./index.module.scss";

const rhinoxCurrencySymbol = "e144a94d1652f16ab94f8f92de53cfba6a34b1b909f3fb80e1784e04";

const marketText = "Mint your RHINOX NFT!";

const RhinoxMarketplace = () => {
  const { data: collections, isLoading } = useCollectionsQuery();

  const renderCollections = () => {
    const col = collections?.find((col) => col.currencySymbol === rhinoxCurrencySymbol);

    if (!col) return <div>No collection found</div>;

    return (
      <CollectionCardRhinox
        key={`${col.name}${col.currencySymbol}`}
        collection={col}
        imageUrl={rhinoxImage}
      />
    );
  };

  return (
    <div className={styles.marketplace__wrapper}>
      <div className={styles.marketplace__rhinox__container}>
        <div className={styles.marketplace__rhinox__heading}>
          <h3 className={styles.marketplace__rhinox__subtitle}>{marketText}</h3>
        </div>
        {isLoading ? (
          <Spinner color="grey" />
        ) : (
          <div className={styles.marketplace__rhinox__nftcard}>
            {renderCollections()}
          </div>
        )}
      </div>
    </div>
  );
};

export default RhinoxMarketplace;
