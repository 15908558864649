import { useState } from "react";
import styles from "./index.module.scss";
import { Input } from "../../../input";
import { Modal } from "../../../Modal";
import { Button } from "../../../button";
import useInviteCode from "../../../../hooks/useInviteCode";
import { useAuthContext } from "../../../../context/AuthContext";
import { useToastMessage } from "../../../../hooks/useToastMessage";

export const signInInitialState = {
  email: "",
  password: "",
};

type Props = {
  open: boolean;
  setOpen: (args: boolean) => void;
};

const SignInModal = ({ open, setOpen }: Props) => {
  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const inviteCode = useInviteCode();
  const { signUp, wallet } = useAuthContext();
  const toast = useToastMessage();

  const handleSignUp = async () => {
    if (!wallet?.walletType) {
      toast.error("Please connect wallet to sign up.");
      return;
    }

    signUp({
      wallet: wallet?.walletType,
      email,
      password,
      inviteCode,
      firstName: "first name",
      lastName: "last name",
      adaAddress: "abc123",
      mailingAddress: "mailing address",
      birthDate: "2000/01/01",
      postalCode: "12345",
    });
  };

  return (
    <Modal showModal={open} onClose={() => setOpen(false)} width={400}>
      <div className={styles.modalContent}>
        <h4 className={styles.title}>Sign Up</h4>
        <Input
          label="Email Address"
          type="email"
          placeholder="example@gmail.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.authInput}
        />
        <Input
          label="Password"
          type="password"
          placeholder="*****"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles.authInput}
        />
        <Button
          onClick={() => {
            handleSignUp();
          }}
          className={styles.authButton}
        >
          Sign up
        </Button>
      </div>
    </Modal>
  );
};

export default SignInModal;
