import styles from "./index.module.scss";
import { Button } from "../button";
import { Spinner } from "../Spinner";
import { useAuthContext } from "../../context/AuthContext";
import { useFindSuitableRewardsPoolQuery, useWithdrawRewardsMutation } from "../../hooks/useRewardPools";
import { makeTransactionUrl } from "../../utils/transaction";
import { useToastMessage } from "../../hooks/useToastMessage";
import { Collection } from "../../api/generated/models";

type NftCardProps = {
  tokenName: string;
  collection: Collection | undefined;
  image: string | undefined;
};

const NftCard = ({
  tokenName,
  collection,
  image,
}: NftCardProps) => {
  const { user } = useAuthContext();
  const toast = useToastMessage();

  const currencySymbol = collection?.currencySymbol ?? "";
  const collectionName = collection?.name ?? "";
  const apy = collection?.apy ?? 0;
  const price = collection?.price ?? 0;
  const prefixLength = collection?.prefixLength ?? 0;

  const { data: rewardsPoolData } = useFindSuitableRewardsPoolQuery({
    currencySymbol,
    tokenName,
    prefixLength,
  });

  const claimableLovelace = rewardsPoolData?.claimableAmount.lovelace ?? 0;
  const claimableAda = claimableLovelace / 1_000_000;
  const assetsCount = Object.keys(rewardsPoolData?.claimableAmount ?? {}).length ?? 0;
  const additionalAssetsMessage = assetsCount > 1 ? ` + ${assetsCount - 1} more` : "";

  const { mutateAsync: withdrawRewards, isPending: isWithdrawingRewards } =
    useWithdrawRewardsMutation();

  const onWithdrawRewards = async () => {
    try {
      if (!user) {
        // This shouldn't be possible
        throw new Error("Please connect wallet to withdraw rewards.");
      }

      if (!rewardsPoolData) {
        throw new Error("No rewards available to withdraw.");
      }

      const withdrawTxHash = await withdrawRewards({
        currencySymbol,
        tokenName,
        rewardsPool: rewardsPoolData.pool,
      });

      toast.success(
        <>
          NFT rewards withdrawal{" "}
          <a
            href={makeTransactionUrl(withdrawTxHash)}
            target="_blank"
            rel="noreferrer"
          >
            transaction
          </a>{" "}
          submitted successfully. You should see the rewards in your wallet
          shortly.
        </>
      );
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.nftCard__container}>
      <div className={styles.nftCard__img}>
        <img src={image} alt="nft" />
      </div>
      <h4 className={styles.nftCard__title}>
        <span className={styles.nftCard__text}>{tokenName}</span>
      </h4>
      <table className={styles.nftCard__details}>
        <tbody>
          <tr>
            <td>Collection</td>
            <td>{collectionName}</td>
          </tr>
          <tr>
            <td>Performance</td>
            <td>{apy}% APY</td>
          </tr>
          <tr>
            <td>Price</td>
            <td>{price.toString()} EUR</td>
          </tr>
          <tr>
            <td>Claimable Rewards</td>
            <td>{claimableAda} ADA{additionalAssetsMessage}</td>
          </tr>
        </tbody>
      </table>
      <div className={styles.nftCard__button_container}>
        <Button
          disabled={!user || isWithdrawingRewards}
          className={styles.nftCard__button}
          onClick={onWithdrawRewards}
        >
          {isWithdrawingRewards ? <Spinner width={20} /> : <>Withdraw Reward</>}
        </Button>
      </div>
    </div>
  );
};

export default NftCard;
