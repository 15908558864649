import styles from "./index.module.scss";
import { Button } from "../../../../components/button";
import { Spinner } from "../../../../components/Spinner";
import { useAuthContext } from "../../../../context/AuthContext";
import { useBuyNftMutation } from "../../../../hooks/useNftCollections";
import { makeTransactionUrl } from "../../../../utils/transaction";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { Collection } from "../../../../api/generated/models";

type CollectionCardRhinoxProps = {
  collection: Collection;
  imageUrl: string;
};

const CollectionCardRhinox = ({
  collection: {
    name,
    currencySymbol,
    remaining,
    price,
  },
  imageUrl,
}: CollectionCardRhinoxProps) => {
  const { user } = useAuthContext();
  const toast = useToastMessage();

  const { mutateAsync: buyNft, isPending: isBuyingNft } = useBuyNftMutation();

  const onBuyNft = async () => {
    try {
      if (!user) {
        throw new Error("Please connect wallet to buy an NFT.");
      }

      const buyTxHash = await buyNft({ currencySymbol });

      toast.success(
        <>
            NFT purchase{" "}
          <a href={makeTransactionUrl(buyTxHash)} target="_blank" rel="noreferrer">
              transaction
          </a>{" "}
            submitted successfully. You should see the NFT in your
            wallet shortly.
        </>
      );
    }
    catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.nftCard__container}>
      <div
        className={styles.nftCard__img}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      >
        {/* <img src={imageUrl} alt="nft" /> */}
      </div>
      <div className={styles.nftCard__details}>
        <div>
          <h4 className={styles.nftCard__title}>
            <span className={styles.nftCard__text}>{name}</span>
          </h4>
        </div>
        <div>
          <table className={styles.nftCard__table}>
            <tbody>
              <tr>
                <td>Availability</td>
                <td>{remaining}</td>
              </tr>
              <tr>
                <td>Price</td>
                <td>{price.toString()} EUR</td>
              </tr>
            </tbody>
          </table>
          <div className={styles.nftCard__button_container}>
            <Button
              disabled={isBuyingNft}
              className={styles.nftCard__button} onClick={onBuyNft}
            >{isBuyingNft ? <Spinner width={20} /> : <>Mint</>}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionCardRhinox;
