import React from "react";
import styles from "./index.module.scss";
import { Button } from "../../button";
import { ReactSVG } from "react-svg";
import twitter from "../../../assets/img/twitter.svg";
import tiktok from "../../../assets/img/tiktok.svg";
import instagram from "../../../assets/img/instagram.svg";
import discord from "../../../assets/img/discord.svg";
import telegram from "../../../assets/img/telegram.svg";

export const Footer = () => {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <ul className={styles.socials}>
          <li>
            <a href="/">
              <ReactSVG src={twitter} />
            </a>
          </li>
          <li>
            <a href="/">
              <ReactSVG src={tiktok} />
            </a>
          </li>
          <li>
            <a href="/">
              <ReactSVG src={instagram} />
            </a>
          </li>
          <li>
            <a href="/">
              <ReactSVG src={discord} />
            </a>
          </li>
          <li>
            <a href="/">
              <ReactSVG src={telegram} />
            </a>
          </li>
        </ul>
        <ul className={styles.menu}>
          <li>
            <a href="/">Contact Us</a>
          </li>
          <li>
            <a href="/">Privacy Policy</a>
          </li>
          <li>
            <a href="/">Cookies Policy</a>
          </li>
          <li className={styles.terms}>
            <a href="/">Terms & conditions</a>
          </li>
        </ul>
        <div className={styles.copyright}>Kwarxs © 2023</div>
      </div>
    </div>
  );
};
